
























import { Component, Vue } from 'vue-property-decorator'
import validation from '../../common/element-val'
import auth, { IpRestrictionModel } from '../../services/authService'
import msg from '../../components/Msg.vue'
import { MessageText } from '@/services/data'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: {
    msg: msg
  }
})
export default class IpRestrictions extends Vue {
  $refs!: {
    ipForm: ElForm;
  }

  isLoading = false
  ip: IpRestrictionModel = {
    IpRestrictionId: 0,
    Ip: null,
    Mask: '0.0.0.0',
    IsAllow: true
  }

  message: MessageText = null

  rules = {
    Ip: [validation.requiredRule(),
      {
        validator: validation.regExp('^((2[0-4]\\d|25[0-5]|[01]?\\d\\d?)\\.){3}(2[0-4]\\d|25[0-5]|[01]?\\d\\d?)$'),
        trigger: 'input'
      }],
    Mask: [validation.requiredRule(),
      {
        validator: validation.regExp('^(254|252|248|240|224|192|128|0)\\.0\\.0\\.0$|^(255\\.(254|252|248|240|224|192|128|0)\\.0\\.0)$|^(255\\.255\\.(254|252|248|240|224|192|128|0)\\.0)$|^(255\\.255\\.255\\.(254|252|248|240|224|192|128|0))$'),
        trigger: 'input'
      }]
  }

  async mounted () {
    try {
      const res = await auth.getIpRestrictionsAsync()
      if (res.Result && res.Result.length > 0) {
        this.ip = res.Result[0]
      }
    } catch (e) {
      this.isLoading = false
      this.message = { error: e }
    }
  }

  submit () {
    this.$refs.ipForm.validate(async (valid: boolean) => {
      if (valid) {
        this.isLoading = true
        try {
          const res = await auth.setIpRestrictionAsync(this.ip)
          this.isLoading = false
          if (res.Error) {
            this.message = { error: this.$t('msg.' + res.Error) as string }
          } else {
            this.message = { success: this.$t('msg.ipRestrictionChanged') as string }
          }
        } catch (e) {
          this.isLoading = false
          this.message = { error: e }
        }
      }
    })
  }
}
