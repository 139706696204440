



























import { Vue, Component } from 'vue-property-decorator'
import auth, { SecurityQuestionModel } from '../../services/authService'
import msg from '../../components/Msg.vue'
import { MessageText } from '@/services/data'
import { ElForm } from 'element-ui/types/form'

export interface QuestionItem {
  Model: SecurityQuestionModel;
  QuestionRules: Record<string, any>;
  AnswerRules: Record<string, any>;
}

@Component({
  components: {
    msg
  }
})
export default class SecurityQuestion extends Vue {
  $refs: {
    questionForm: ElForm;
  }

  async mounted () {
    const res = await auth.getSecurityQuestionsAsync()
    if (res.Result) {
      res.Result.forEach(item => this.addQuestion(item))
      while (this.questions.items.length < 3) {
        this.addQuestion({ SecurityQuestionId: 0, Question: null, Answer: null })
      }
    } else {
      await this.$alert(res.Error)
    }
  }

  isLoading = false
  questions: { items: QuestionItem[] } = {
    items: []
  }

  message: MessageText = null

  addQuestion (model?: SecurityQuestionModel) {
    this.questions.items.push(
      {
        Model: model || { Answer: '', Question: '', SecurityQuestionId: 0 },
        QuestionRules: {
          required: true,
          message: this.$t('val.required'),
          trigger: 'blur'
        },
        AnswerRules: {
          required: true,
          message: this.$t('val.required'),
          trigger: 'blur'
        }
      }
    )
  }

  submit () {
    this.$refs.questionForm.validate(async (valid: boolean) => {
      if (valid) {
        this.isLoading = true
        try {
          const postData = this.questions.items.map(q => q.Model)
          const res = await auth.setSecurityQuestionsAsync(postData)
          this.isLoading = false
          if (res.Error) {
            this.message = { error: this.$t('msg.' + res.Error) as string }
          } else {
            this.message = { success: this.$t('msg.questionSaved') as string }
          }
        } catch (e) {
          this.isLoading = false
          this.message = { error: e }
        }
      }
    })
  }
}
