








































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import changePwd from './ChangePwd.vue'
import ipRestriction from './IpRestriction.vue'
import questions from './SecurityQuestion.vue'
import rowEditor from '../../components/TextRowEditor.vue'
import numberRowSelector from '../../components/NumberRowSelector.vue'
import memberService, { TradeExecutionEmailModel, MemberUpdateModel } from '../../services/memberService'
import settingService from '@/services/settingService'
import { ApiBooleanResult } from '@/services/data'
import numberRowEditor from '@/components/NumberRowEditor.vue'

@Component({
  components: {
    numberRowEditor,
    changePwd,
    ipRestriction,
    questions,
    rowEditor,
    numberRowSelector
  }
})
export default class UserSettings extends Vue {
  editing: string = null
  tradeExecutionEmails: TradeExecutionEmailModel[] = []
  communication: MemberUpdateModel = {
    mobile: null
  }

  userSettings: Record<string, any> = {
    TradeSmsNotification: false,
    TradeWebNotification: false,
    GatewayTelegramNotification: false,
    GatewayWebNotification: false,
    StatementDelAddr: '',
    CommunicateEmail: '',
    MaxNotFrequency: 0,
    FreebieTelegramNotification: false,
    FreebieWebNotification: false,
    AllTelegramNotification: false,
    AllWebNotification: false,
    FreebieOnOff: false,
    IBKRAcc: '',
    FlexToken: '',
    FlexTradeId: '',
    FlexDividendId: ''
  }

  sysSettings = {
    NotificationBot: {
      Token: '',
      NameOfBot: ''
    }
  }

  async mounted () {
    const res = await memberService.getCurrentAsync()
    if (res.Error) {
      await this.$alert(res.Error)
    }
    this.communication = res.Result
    const emailRes = await memberService.getTradeExecutionEmailsAsync()
    if (emailRes.Error) {
      await this.$alert(emailRes.Error)
    }
    const emailResult = emailRes.Result
    if (emailResult) {
      if (emailResult.length > 0) {
        this.tradeExecutionEmails = emailResult
      }
    }
    const keys = Object.keys(this.userSettings)
    const usRes = await settingService.readUserSettingsByNamesAsync(keys)
    if (usRes.Error) {
      await this.$alert(usRes.Error)
    }
    if (usRes.Result) {
      usRes.Result.forEach(p => {
        if (p.Value === 'true') this.userSettings[p.Name] = true
        else if (p.Value === 'false') this.userSettings[p.Name] = false
        else {
          this.userSettings[p.Name] = p.Value
        }
      })
    }

    const sysRes = await settingService.readSysSettingByNamesAsync(['NotBot'])
    const notBotItem = sysRes.Result.find(p => p.Name === 'NotBot')
    if (notBotItem) {
      const notBotObj = JSON.parse(notBotItem.Value)
      this.sysSettings.NotificationBot.Token = notBotObj.Token
      this.sysSettings.NotificationBot.NameOfBot = notBotObj.NameOfBot
    }
  }

  async updateMemberField (field: string) {
    const object: MemberUpdateModel = {}
    object[field] = this.communication[field]
    const res = await memberService.updateAsync(object)
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.showMessage(res)
    }
  }

  async updateUserSetting (field: string) {
    const value = this.userSettings[field].toString()
    const res = await settingService.addOrUpdateUserSettingAsync([{
      Name: field,
      Value: value,
      ValueType: 'Number'
    }])
    this.showMessage(res)
  }

  async saveTradeExecutionEmail (data: TradeExecutionEmailModel) {
    const res = await memberService.updateTradeExecutionEmailsAsync([data])
    this.showMessage(res)
  }

  private showMessage (res: ApiBooleanResult) {
    if (res.Error) {
      this.$message({ message: this.$t('msg.' + (res.Error || 'unableToSave')) as string, type: 'error' })
    } else {
      this.$message({ message: this.$t('msg.dataSaved') as string, type: 'success' })
    }
  }
}
