




























import { Component, Vue } from 'vue-property-decorator'
import validation from '../../common/element-val'
import auth, { ChangePwdModel } from '../../services/authService'
import msg from '../../components/Msg.vue'
import { ElForm } from 'element-ui/types/form'
import { MessageText } from '@/services/data'

@Component({
  components: {
    msg: msg
  }
})
export default class ChangePwd extends Vue {
  $refs: {
    pwdForm: ElForm;
  }

  isLoading = false
  pwd: ChangePwdModel = {
    OriPassword: '',
    NewPassword: '',
    Confirm: ''
  }

  message: MessageText = null
  rules = {
    OriPassword: validation.requiredRule(),
    NewPassword: validation.requiredRule(),
    Confirm: [{
      validator: validation.required(),
      trigger: 'blur'
    }, {
      validator: validation.compare()(() => this.pwd.NewPassword),
      trigger: 'blur'
    }]
  }

  submit () {
    this.$refs.pwdForm.validate(async (valid: boolean) => {
      if (valid) {
        this.isLoading = true
        try {
          const res = await auth.changePwdAsync(this.pwd)
          this.isLoading = false
          if (res.Error) {
            this.message = { error: this.$t('msg.' + res.Error) as string }
          } else {
            this.message = { success: this.$t('msg.passwordChanged') as string }
          }
        } catch (e) {
          this.isLoading = false
          this.message = { error: e }
        }
      }
    })
  }
}
